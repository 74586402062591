<template>
    <div v-if="isNew" class="mb-5">
        <h1>Nieuwe dagstaat</h1>
        <div class="row mt-3">
            <label class="col-3">{{$t('navigation.locations')}}</label>
            <div class="col-3 col-select">
                <select v-model="item.location_id">
                    <option v-for="option in locations" :key="option.id" :value="option.id">{{option.name}}</option>
                </select>
            </div>
        </div>
        <FormItem class="pe-2 w-50 mt-2" id="multidate" type="slider" :label="$t('daystate.select_multiple_dates')" sizeLabel="6" sizeItem="6" extraOption="align-left" 
                  v-model="chooseMultipleDates" :options="[1,0]" @change="setDateLimit" />
        <div class="row mt-2">
            <label class="col-3">{{$t(chooseMultipleDates ? 'overview.from' : 'overview.date')}}</label>
            <input type="date" class="col-3" v-model="item.from_date" @change="setDateLimit" />
        </div>
        <div class="row mt-2" v-if="chooseMultipleDates">
            <label class="col-3">{{$t('overview.to')}}</label>
            <input type="date" class="col-3" :disabled="!item.from_date" v-model="item.to_date" :min="minDate" :max="maxDate" />
        </div>
        <FooterButtons class="mt-5" @prev="back" @save="save" :disabled="isSaving" />
    </div>
    <div v-else class="mb-5">
        <div v-if="loading" id="loadingscreen">
            <div role="status">
                <i class="fa fa-spinner fa-xl fa-spin me-2"></i>{{$t('form.please_wait')}}
            </div>
        </div>
        <div class="d-flex justify-content-between mb-5">
            <div>
                <h1>{{$t('navigation.daystates')}}: {{item.location.full_name}}</h1>
                <div class="text-gray fs-15">{{formatDate(item.current_date, 'longDate')}}</div>
            </div>
            <div class="d-flex">
                <!--<div class="text-warning text-end me-2 pt-2">Kassa 4 is tijdelijk niet bereikbaar!<br><a href="#" class="text-inherit">Bekijk de oplossingen</a></div>
                <div class="bg-warning warning-badge me-2"><i class="fal fa-xl fa-warning text-light"></i></div>-->
                <!--<div class="bg-unilight big-badge"></div>-->
                <template v-if="item.location.icon_url || (item.location.franchise ? item.location.franchise.icon_url : false)">
                <img :src="item.location.icon_url || (item.location.franchise ? item.location.franchise.icon_url : false)" width="60" class="big-badge" />
                </template>
            </div>
        </div>
        <div class="d-flex justify-content-end action-bar">
            <button type="button" id="dropdownMenuButton2" class="btn btn-unidark btn-sec-overview ps-4" data-bs-toggle="dropdown" aria-expanded="false">
                {{$tc('overview.actions', 2)}} <i class="fa-light fa-chevron-down float-end pt-1 ms-4 me-1"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                <li>
                    <a class="dropdown-item" @click="importData">
                        <i class="fal fa-download me-2"></i> Dagstaat importeren
                    </a>
                    <a class="dropdown-item" @click="exportData">
                        <i class="fal fa-upload me-2"></i> Dagstaat exporteren
                    </a>
                    <a class="dropdown-item" @click="downloadExport">
                        <i class="fal fa-file-circle-info" style="margin-right: .4rem"></i> Download export details
                    </a>
                </li>
            </ul>
        </div>
        <ul class="nav nav-tabs mb-4" id="myTab" role="tablist" style="font-size: 0.95rem !important">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="info-1-tab" data-bs-toggle="tab" data-bs-target="#info-1" type="button" role="tab" aria-controls="info-1" aria-selected="true">{{$t('navigation.daystates')}}</button>
            </li>
            <li class="nav-item" role="presentation" v-if="item.location.check_daystates">
                <button class="nav-link" id="info-2-tab" data-bs-toggle="tab" data-bs-target="#info-2" type="button" role="tab" aria-controls="info-2">{{$t('overview.check')}}</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="info-3-tab" data-bs-toggle="tab" data-bs-target="#info-3" type="button" role="tab" aria-controls="info-3">{{$tc('navigation.logs', 2)}}</button>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active pt-3" id="info-1" role="tabpanel" aria-labelledby="info-1-tab">
                <h2><i class="far fa-calendar me-2 fs-15"></i>{{$t('daystate.information')}}</h2>
                <div class="row mt-2">
                    <div class="col-3 fs-14 text-gray">{{$t('navigation.locations')}}</div>
                    <div class="col-3 fs-14 text-gray">{{$t('overview.date')}}</div>
                    <div class="col-3 fs-14 text-gray">Import</div>
                    <div class="col-3 fs-14 text-gray">Export</div>
                </div>
                <div class="row">
                    <div class="col-3 fs-15">{{item.name}}</div>
                    <div class="col-3 fs-15">{{formatDate(item.current_date, 'longDate')}}</div>
                    <div class="col-3 fs-15" v-if="isImporting">
                        <i class="fas fa-spinner fa-spin"></i> Bezig met importeren...<br>
                        <span>&nbsp;</span>
                    </div>
                    <div class="col-3 fs-15" v-else>
                        {{item.import_sync ? formatDate(item.import_sync, 'longDate') : ""}}<br>
                        <span class="text-gray">{{formatDate(item.import_sync, 'time')}}</span>
                    </div>
                    <div class="col-3 fs-15" v-if="isExporting">
                        <i class="fas fa-spinner fa-spin"></i> Bezig met exporteren...<br>
                        <span>&nbsp;</span>
                    </div>
                    <div class="col-3 fs-15" v-else>
                        {{formatDate(item.export_sync, 'longDate')}}<br>
                        <span class="text-gray">{{formatDate(item.export_sync, 'time')}}</span>
                    </div>
                </div>

                <br>
                <ul class="nav nav-tabs mb-5" id="myTab2" role="tablist" style="font-size: 0.95rem !important">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="view-1-tab" data-bs-toggle="tab" data-bs-target="#view-1" type="button" role="tab" aria-controls="view-1" aria-selected="true">{{$t('daystate.view_1')}}</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="view-2-tab" data-bs-toggle="tab" data-bs-target="#view-2" type="button" role="tab" aria-controls="view-2">{{$t('daystate.view_2')}}</button>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTab2Content">
                    <div class="tab-pane fade show active da-flex" id="view-1" role="tabpanel" aria-labelledby="view-1-tab">
                      <section class="pe-4">
                        <h2><i class="far fa-coins me-2 fs-15"></i>{{$t('overview.income')}} <span class="fw-medium ms-2 fs-14">({{formatCurrency(totalIncome, true)}})</span></h2>
                        <div class="row py-2 mt-1">
                            <div v-show="splitCostPlace" class="col-2 fs-14 text-gray">{{$t('location.cost_place')}}</div>
                            <div :class="{'col-2': splitCostPlace, 'col-3': !splitCostPlace}" class="fs-14 text-gray">{{$t('daystate.grootboek')}}</div>
                            <div :class="{'col-4': splitCostPlace, 'col-5': !splitCostPlace}" class="fs-14 text-gray">{{$t('overview.name')}}</div>
                            <div class="col-4 fs-14 text-gray text-end">{{$t('daystate.subtotal')}}</div>
                        </div>
                        <div class="row border-top py-2" v-for="row in item.consolidated_income.lines" :key="row.name">
                            <div v-show="splitCostPlace" class="col-2 fs-15">{{row.salesarea}}</div>
                            <div :class="{'col-2': splitCostPlace, 'col-3': !splitCostPlace}" class="fs-15">{{row.glaccount}}</div>
                            <div :class="{'col-4': splitCostPlace, 'col-5': !splitCostPlace}" class="fs-15">
                                {{row.name}}
                                <template v-if="row.export_exclude == true">
                                    <FormItem type="icon" :id="'exclude_income_'+(row.name ? row.name.replaceAll(' ', '_') : 'u')" :infoIconText="$t('popovers.daystate.excluded_income')" />
                                </template>
                                <template v-if="(row.is_cash == true && item.consolidated_income.total_cash_tip != 0) && item.location.show_tip == 0">
                                    <FormItem type="icon" :id="'exclude_income_'+(row.name ? row.name.replaceAll(' ', '_') : 'u')" :infoIconText="$t('popovers.daystate.income_tip_message', [formatCurrency(item.consolidated_income.tip, true)])" />
                                </template>
                          </div>
                              <div class="col-4 fs-15 text-end">{{formatCurrency(row.subtotal, true)}}</div>
                          </div>
                            <div class="row border-top py-2 negative" v-if="crossIncome">
                                <div class="col-3 fs-15">-</div>
                                <div class="col-5 fs-15">{{$t('daystate.cross')}}</div>
                                <div class="col-4 fs-15 text-end">{{formatCurrency(crossIncome, true)}}</div>
                            </div>
                            <div class="row border-top pt-2">
                                <div class="col-8 total-row">{{$t('daystate.total')}}</div>
                                <div class="col-4 text-end total-row">{{formatCurrency(totalIncome + crossIncome, true)}}</div>
                            </div>
                        </section>

                        <section class="accordion ps-4">
                            <h2><i class="far fa-money-bill me-2 fs-15"></i>{{$t('overview.revenue')}} <span class="fw-medium ms-2 fs-14">({{formatCurrency(totalRevenue, true)}})</span></h2>
                            <div class="row py-2 mt-1">
                                <div v-show="splitCostPlace" class="col-2 fs-14 text-gray">{{$t('location.cost_place')}}</div>
                                <div class="col-2 fs-14 text-gray">{{$t('daystate.grootboek')}}</div>
                                <div :class="{'col-3': splitCostPlace, 'col-5': !splitCostPlace}" class="fs-14 text-gray">{{$t('overview.name')}}</div>
                                <div class="col-2 fs-14 text-gray text-end pe-0">{{$t('daystate.per_piece')}}</div>
                                <div class="col-2 fs-14 text-gray text-end">{{$t('daystate.subtotal')}}</div>
                            </div>
                            <template v-for="(row, rindex) in item.consolidated_revenue.lines" :key="row.name">
                                <div class="row border-top py-2 accordion-header hoverable" :class="{negative: row.subtotal < 0}" @click="openCollapse($event, rindex)">
                                    <div v-show="splitCostPlace" class="col-2 fs-15">{{row.salesarea}}</div>
                                    <div class="col-2 fs-15">{{row.glaccount}}</div>
                                    <div :class="{'col-3': splitCostPlace, 'col-5': !splitCostPlace}" class="fs-15">{{row.name}}</div>
                                    <div class="col-4 fs-15 text-end">{{formatCurrency(row.subtotal, true)}}</div>
                                    <div class="col-1">
                                        <button class="accordion-button collapsed col-1 p-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#BS_'+row.name.replaceAll(/[^A-Za-z0-9]/g, '')"
                                                aria-expanded="true" :aria-controls="'BS_'+row.name.replaceAll(/[^A-Za-z0-9]/g, '')" v-show="row.subtotal" :id="'record-'+rindex"> </button>
                                    </div>
                                </div>
                                <div :id="'BS_'+row.name.replaceAll(/[^A-Za-z0-9]/g, '')" class="accordion-collapse collapse text-dlight pb-1">
                                    <template v-for="iditem, iditemindex in row.items" :key="iditemindex">
                                        <div class="row" v-for="lineitem in iditem" :key="lineitem.name">
                                              <div class="col-2 fs-14">{{lineitem.quantity}}x</div>
                                              <div class="col-5 fs-14">
                                                <template v-if="lineitem.export_exclude == true">
                                                    <FormItem type="icon" :id="'exclude_rev_'+iditemindex+'_'+(row.name ? row.name.replaceAll(' ', '_') : 'u')" :infoIconText="$t('popovers.daystate.excluded_revenue')" />
                                                </template>
                                                {{lineitem.name}}</div>
                                            <div class="col-2 fs-14 text-end pe-1">{{formatCurrency(lineitem.single_price, true)}}</div>
                                            <div class="col-2 fs-14 text-end ps-1">{{formatCurrency(lineitem.price, true)}}</div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <div class="row border-top py-2 negative" v-if="crossRevenue">
                                <div v-show="splitCostPlace" class="col-2 fs-15">-</div>
                                <div class="col-2 fs-15">-</div>
                                <div :class="{'col-3': splitCostPlace, 'col-5': !splitCostPlace}" class="fs-15">{{$t('daystate.cross')}}</div>
                                <div class="col-4 fs-15 text-end">{{formatCurrency(crossRevenue, true)}}</div>
                            </div>
                            <div class="row border-top pt-2">
                                <div class="col-7 total-row">{{$t('daystate.total')}}</div>
                                <div class="col-4 text-end total-row">{{formatCurrency(totalRevenue + crossRevenue, true)}}</div>
                            </div>
                        </section>

                        <section v-if="totalDeposit" class="w-100 pt-2">
                            <h2><i class="far fa-money-simple-from-bracket me-2 fs-15"></i>{{$t('daystate.deposits')}}</h2>
                            <div class="row py-2 mt-1">
                                <div class="col-1 fs-14 text-gray">{{$t('overview.time')}}</div>
                                <div class="col-2 fs-14 text-gray">{{$t('overview.type')}}</div>
                                <div class="col fs-14 text-gray">{{$t('navigation.users')}}</div>
                                <div class="col fs-14 text-gray">{{$t('daystate.cash_register')}}</div>
                                <div class="col-2 fs-14 text-gray">{{$t('daystate.paymethod')}}</div>
                                <div class="col-2 fs-14 text-gray text-end">{{$t('daystate.subtotal')}}</div>
                            </div>
                            <div class="row border-top py-2" v-for="row in item.daystate_deposits" :key="row.name">
                                <div class="col-1 fs-15">{{formatDate(row.time, 'timeShort')}}</div>
                                <div class="col-2 fs-15">{{row.card_type}}</div>
                                <div class="col fs-15">{{row.user_name}}</div>
                                <div class="col fs-15">{{row.computer_name}}</div>
                                <div class="col-2 fs-15">{{row.paymentmethod.name}}</div>
                                <div class="col-2 fs-15 text-end">{{formatCurrency(row.amount, true)}}</div>
                            </div>
                            <div class="row border-top pt-2">
                                <div class="col-10 total-row">{{$t('daystate.total')}}</div>
                                <div class="col-2 text-end total-row">{{formatCurrency(totalDeposit, true)}}</div>
                            </div>
                        </section>
                    </div>
                    <div class="tab-pane fade" id="view-2" role="tabpanel" aria-labelledby="view-2-tab">
                        <div class="d-flex justify-content-end">
                            <FormItem type="download" :service="downloadExcel" label="Download Excel-export" endTag="fa-download" />
                        </div>
                        <div v-html="bookkeeppingTable"></div>
                    </div>
                </div>
                <FooterButtons @prev="back" @save="save" :disabled="isSaving" />
            </div>



            <div class="tab-pane" id="info-2" role="tabpanel" aria-labelledby="info-2-tab" v-if="item.location.check_daystates" :class="{'finalised': item.finalised}">
                <div id="progress-bar" class="pt-3 mb-4 navigatable" :style="'--steps: ' + stepData.length">
                    <div v-for="thisstep in stepData" :key="thisstep.id" :class="{active: activeUntil >= thisstep.id, current: step == thisstep.id, incomplete: thisstep.incomplete}" @click="setStep(thisstep.id)">
                        <i :class="thisstep.icon"></i> <div>{{$t('daystatetabs.step_' + thisstep.id)}}</div>
                    </div>
                    <div :class="{active: activeUntil >= 6, current: step == 6}" @click="setStep(6)">
                        <i class="far fa-check default-color"></i> <div>{{$t('daystatetabs.step_7')}}</div>
                    </div>
                </div>
                <hr class="mt-2 mb-5">

                <section v-show="step == 1" id="step-1">
                    <h2 class="pb-2"><i class="far fa-mobile me-2 fs-15"></i>{{$t('daystatetabs.step_1')}}</h2>
                    <div class="d-flex gap-3 py-1 w-100">
                        <div class="col-3 text-gray">{{$t('daystate.automaton')}}</div>
                        <div class="col text-gray text-end" v-for="payType of payTypesArray" :key="payType">{{payType.name}}</div>
                        <div class="col text-gray text-end">{{$t('daystate.total')}}</div>
                    </div>

                    <div class="d-flex gap-3 p-row border-top" v-for="stockItem in item.payTypes" :key="stockItem.id">
                        <div class="col-3 pt-x">{{stockItem.name}}</div>
                        <div class="col" v-for="payType in payTypesArray" :key="payType">
                            <FormItem type="currency-and-amount" :id="'group-'+stockItem.id+'-'+payType" startTag="€" v-model="stockItem[payType.id].amount" />
                        </div>
                        <div class="col pt-x text-end">{{calculateTotal(stockItem, payTypesArray)}}</div>
                    </div>
                    <div class="d-flex gap-3 pt-1 border-top w-100">
                        <div class="col-3 fw-bold">{{$t('daystate.total')}}</div>
                        <div class="col fw-bold text-end" v-for="payType in payTypesArray" :key="payType">{{calculateTotal(payType.id, null, item.payTypes)}}</div>
                        <div class="col fw-bold text-end total-row">{{calculateTotal({}, payTypesArray, item.payTypes, 0, 'saveatm')}}</div>
                    </div>
                    <div class="d-flex gap-3 pt-1 border-top justify-content-end w-100">
                        <div class="fw-bold">{{$t('overview.income')+' ('+formatCurrency(totalEFT - depositsEFT, 1)}}) +
                            {{$t('daystate.deposits_eft')+' ('+formatCurrency(depositsEFT, 1)}})</div>
                        <div class="fw-bold total-row text-end" style="width: 160px">{{formatCurrency(totalEFT, 1)}}</div>
                    </div>
                    <div class="d-flex gap-3 pt-1 border-top justify-content-end w-100">
                        <div class="fw-bold">{{$t('daystate.difference')}}</div>
                        <div class="fw-bold total-row text-end" style="width: 160px">{{formatCurrency(totalEFT - totalATMs, 1)}}</div>
                    </div>
                </section>
                <section v-show="step == 2" id="step-2">
                    <h2 class="pb-2"><i class="far fa-wallet me-2 fs-15"></i>{{$t('daystatetabs.step_2')}}</h2>
                    <div class="row py-1 text-gray">
                        <div class="col-1">{{$t('overview.time')}}</div>
                        <div class="col-2">{{$t('daystate.supplier')}}</div>
                        <div class="col-3">{{$t('form.description')}}</div>
                        <div class="col">{{$t('navigation.users')}}</div>
                        <div class="col">{{$t('daystate.cash_register')}}</div>
                        <div class="col text-end">{{$t('overview.income')}}</div>
                        <div class="col text-end">{{$t('overview.expenses')}}</div>
                    </div>
                    <div class="row border-top p-row" v-for="stockItem in item.daystate_check_income_and_expenses" :key="stockItem.name">
                        <div class="col-1 fw-bold pt-x">
                            {{formatDate(stockItem.logged_time, 'timeShort')}}
                            <i class="float-end far fa-warning text-warning pt-1" v-show="stockItem.supplier && stockItem.supplier.withdraw_from_safe" title="Wordt onttrokken uit kluis"></i>
                        </div>
                        <div class="col-2">
                            <FormItem type="livesearch" :options="suppliers" :id="'live-'+stockItem.id+'-gla'" v-model="stockItem.supplier_id"
                                     @onchange="(a,b,c) => setSupplier(c, stockItem)" :content="stockItem.supplier_name" />
                        </div>
                        <div class="col-3 pt-x">{{stockItem.description}}</div>
                        <div class="col pt-x">{{stockItem.user_name	}}</div>
                        <div class="col pt-x">{{stockItem.computer_name	}}</div>
                        <div class="col pt-x text-end"> {{formatCurrency(stockItem.income, 2)}} </div>
                        <div class="col pt-x text-end"> {{formatCurrency(stockItem.expense, 2)}} </div>
                    </div>
                    <div class="d-flex border-top pt-1 w-100">
                        <div class="col-9 fw-bold">{{$t('daystate.total')}}</div>
                        <div class="col fw-bold text-end pe-1">{{formatCurrency(item.daystate_check_income_and_expenses.reduce((sum, val) => sum + Number(val.income), 0), true)}}</div>
                        <div class="col fw-bold text-end total-row">{{formatCurrency(item.daystate_check_income_and_expenses.reduce((sum, val) => sum + Number(val.expense), 0), true)}}</div>
                    </div>
                </section>
                <section v-show="step == 3" class="w-50" id="step-3">
                    <h2 class="pb-2"><i class="far fa-coins me-2 fs-15"></i>{{$t('daystatetabs.step_3')}}</h2>
                    <div class="row py-2 mt-1">
                        <div class="col-8 fs-14 text-gray">{{$t('overview.name')}}</div>
                        <div class="col-4 fs-14 text-gray text-end">{{$t('daystate.subtotal')}}</div>
                    </div>
                    <div class="border-top py-2" :class="(item.consolidated_income.total_cash ? 'row' : 'd-none')">
                        <div class="col-8 fs-15">{{$t('daystate.cash_total')}}</div>
                        <div class="col-4 fs-15 text-end">{{formatCurrency(item.consolidated_income.total_cash, true)}}</div>
                    </div>
                    <div class="border-top py-2" :class="(totalEFT - totalATMs != 0 ? 'row' : 'd-none')">
                        <div class="col-8 fs-15">{{$t('daystate.difference_etf')}}</div>
                        <div class="col-4 fs-15 text-end">{{formatCurrency(totalEFT - totalATMs, true)}}</div>
                    </div>
                    <div class="border-top py-2" :class="(diffIncomeExpenses ? 'row' : 'd-none')">
                        <div class="col-8 fs-15">{{$t('daystatetabs.step_2')}}</div>
                        <div class="col-4 fs-15 text-end">{{formatCurrency(diffIncomeExpenses, true)}}</div>
                    </div>
                    <div class="border-top py-2" :class="(depositsCash ? 'row' : 'd-none')">
                        <div class="col-8 fs-15">{{$t('daystate.deposits_total')}}</div>
                        <div class="col-4 fs-15 text-end">{{formatCurrency(depositsCash, true)}}</div>
                    </div>
                    <div class="row border-top fw-bold py-2 negative">
                        <div class="col-8">{{$t('daystate.total')}}</div>
                        <div class="col-4 text-end">{{formatCurrency(totalCash, true)}}</div>
                    </div>
                </section>
                <section v-show="step == 4" id="step-4">
                    <h2 class="pb-2"><i class="far fa-desktop me-2 fs-15"></i>{{$t('daystatetabs.step_4')}}</h2>
                    <div class="d-flex align-items-end py-1 gap-3 w-100">
                        <div class="col-auto text-gray">{{$t('daystate.paper_money')}}</div>
                        <div class="col text-gray" v-for="payType in cashTypesArray" :key="payType">{{payType.name}}</div>
                        <div class="col-auto text-gray text-end">{{$t('daystate.total')}}</div>
                    </div>
                    <div class="d-flex gap-3 p-row border-top" v-for="stockItem in item.bills" :key="stockItem.name">
                        <div class="col-auto fw-bold pt-1">
                            <img :src="'../../bills/'+stockItem.name+'.jpg'" height="24" />
                            <span class="ps-4">€ {{stockItem.name}},-</span>
                        </div>
                        <div class="col" v-for="payType in cashTypesArray" :key="payType">
                            <FormItem type="number-with-tags" :id="'group-'+stockItem.id+'-'+payType" startTag="x" v-model="stockItem[payType.id].amount" />
                        </div>
                        <div class="col-auto pt-x text-end">{{calculateTotal(stockItem, cashTypesArray, null, true)}}</div>
                    </div>
                    <div class="d-flex gap-3 pt-1 border-top w-100">
                        <div class="col-auto fw-bold">{{$t('daystate.subtotal')}}</div>
                        <div class="col fw-bold text-end" v-for="payType in cashTypesArray" :key="payType">{{calculateTotal(payType, null, item.bills, true)}}</div>
                        <div class="col-auto fw-bold text-end total-row">{{calculateTotal(payType, cashTypesArray, item.bills, true)}}</div>
                    </div>
                    <div class="d-flex mt-3 w-100">
                        <div class="col-1" style="width: auto">
                            <FormItem class="pe-2" id="coinspecifier" type="slider" v-model="item.daystate_check_cash_accounting.specify_coins" :options="[1,0]" />
                        </div>
                        <label class="col-1 fw-bold pt-1" for="coinspecifier" style="width: 300px">{{$t('daystate.coin_money_specify')}}</label>
                    </div>
                    <template v-if="item.daystate_check_cash_accounting.specify_coins">
                        <div class="d-flex align-items-end py-1 gap-3 w-100">
                            <div class="col-auto text-gray">{{$t('daystate.coin_money')}}</div>
                            <div class="col text-gray" v-for="payType in cashTypesArray" :key="payType">{{payType.name}}</div>
                            <div class="col-auto text-gray text-end">{{$t('daystate.total')}}</div>
                        </div>
                        <div class="d-flex gap-3 p-row border-top" v-for="stockItem in item.coins" :key="stockItem.name">
                            <div class="col-auto fw-bold pt-1">
                                <img :src="'../../bills/'+(stockItem.id == 0.05 ? 'cent' : (stockItem.id >= 1 ? stockItem.name : 'centen'))+'.png'" height="24" />
                                <span class="ps-4" style="position: relative; top: 1px">€ {{stockItem.name + (stockItem.id >= 1 ? ',-' : '')}}</span>
                            </div>
                            <div class="col" v-for="payType in cashTypesArray" :key="payType">
                                <FormItem type="number-with-tags" :id="'group-'+stockItem.id+'-'+payType" startTag="x" v-model="stockItem[payType.id].amount" />
                            </div>
                            <div class="col-auto pt-x text-end">{{calculateTotal(stockItem, cashTypesArray, null, true)}}</div>
                        </div>
                        <div class="d-flex gap-3 pt-1 border-top w-100">
                            <div class="col-auto fw-bold">{{$t('daystate.subtotal')}}</div>
                            <div class="col fw-bold text-end" v-for="payType in cashTypesArray" :key="payType">{{calculateTotal(payType, null, item.coins, true)}}</div>
                            <div class="col-auto fw-bold text-end total-row">{{calculateTotal(payType, cashTypesArray, item.coins, true)}}</div>
                        </div>
                    </template>
                    <div class="d-flex gap-3 pt-1">
                        <div class="col-auto fw-bold pt-1">{{item.daystate_check_cash_accounting.specify_coins ? 'Overig muntgeld' : 'Totaal muntgeld'}}</div>
                        <div class="col" v-for="payType in cashTypesArray" :key="payType">
                            <FormItem type="currency-and-amount" :id="'group-'+payType+'-totals'" startTag="€" v-model="item.coinTotals[payType.id].amount" />
                        </div>
                        <div class="col-auto pt-x text-end">{{formatCurrency(Object.values(item.coinTotals).reduce((sum, val) => sum + Number(val.amount), 0), true)}}</div>
                    </div>

                    <div class="d-flex gap-3 mt-2 w-100">
                        <div class="col-auto fw-bold total-row mb-2">{{$t('daystate.total')}}</div>
                        <div class="col fw-bold text-end total-row" v-for="payType in cashTypesArray" :key="payType">{{calculateGrandTotal(payType, null)}}</div>
                        <div class="col-auto fw-bold text-end total-row">{{calculateGrandTotal(payType, cashTypesArray)}}</div>
                    </div>

                    <div style="width: calc(60% - 7rem)">
                        <template v-if="differenceInTotals">
                            <div class="row mt-1 w-100">
                                <div class="col-4 fw-bold">{{$t('daystate.cash_difference')}}</div>
                                <div class="col-auto fw-bold text-danger ps-3">{{formatCurrency(item.daystate_check_cash_accounting.cash_difference, true)}}</div>
                            </div>
                            <div class="row mt-2 w-100" v-if="checkCounting">
                                <div class="col-4 fw-bold" style="padding-top: 6px">{{$t('daystate.cash_difference_accept')}}</div>
                                <div class="col-auto ps-3 fw-bold">
                                    <FormItem id="saldo-accept" type="slider" v-model="item.daystate_check_cash_accounting.accept_cash_difference" :options="[1,0]" style="width: 56px" />
                                </div>
                            </div>
                        </template>
                        <FormItem type="text" v-model="item.daystate_check_accountability.counted_by_name" :label="$t('daystate.name_countedby')" :required="!checkCounting" />
                        <FormItem type="textarea" :id="'daystatetextarea'" v-model="item.daystate_check_cash_accounting.notes" :options="5" :label="$t('form.remark')" />
                    </div>
                </section>
                <section v-show="step == 5" class="w-50" id="step-5">
                    <h2 class="pb-2"><i class="far fa-vault me-2 fs-15"></i>{{$t('daystatetabs.step_5')}}</h2>
                    <div class="row py-2 mt-1">
                        <div class="col-9 fs-14 text-gray">{{$t('overview.name')}}</div>
                        <div class="col-3 fs-14 text-gray text-end">{{$t('daystate.subtotal')}}</div>
                    </div>
                    <div class="row border-top py-2">
                        <div class="col-9 fs-15">{{$t('daystate.saldo_prev')}}</div>
                        <div class="col-3 fs-15 text-end">{{formatCurrency(item.daystate_check_safe.old_amount, true)}}</div>
                    </div>
                    <div class="row border-top py-2" v-if="canSeeSafeMutations">
                        <div class="col-9 fs-15 pt-2">{{$t('daystate.cash_difference_accept_2')}}</div>
                        <div class="col-3 fs-15 text-end">
                            <FormItem type="slider" :id="'saldo-two'" v-model="item.daystate_check_safe.accept_difference" :options="[1,0]" @onchange="setCurrentAmount(item)" />
                        </div>
                    </div>
                    <div class="row border-top py-2" v-if="item.daystate_check_safe.accept_difference">
                        <div class="col-9 fs-15 pt-x">{{$t('daystate.saldo_curr')}}</div>
                        <div class="col-3 fs-15" v-if="canSeeSafeMutations">
                            <FormItem type="currency-and-amount" :id="'saldo-one'" startTag="€" extraOption="fs-15" v-model="item.daystate_check_safe.current_amount" @onchange="setNewAmount(item)" />
                        </div>
                        <div class="col-3 fs-15 text-end py-2" v-else> {{formatCurrency(item.daystate_check_safe.current_amount, true)}} </div>
                    </div>
                    <div class="row border-top py-2" v-if="totalOnttroken">
                        <div class="col-9 fs-15">{{$t('daystate.cash_removed')}} </div>
                        <div class="col-3 fs-15 text-end">{{formatCurrency(totalOnttroken * -1, true)}}</div>
                    </div>
                    <div class="row border-top py-2">
                        <div class="col-9 fs-15">{{$t('daystate.cash_obtained')}} - <span class="text-muted text-capitalize fs-14">{{formatDate(item.daystate_check_cash_accounting.updated_at, 'verylongDate')}}</span></div>
                        <div class="col-3 fs-15 text-end">{{calculateGrandTotal(null, cashTypesArray)}}</div>
                    </div>
                    <div class="row border-top py-3 fw-bold negative">
                        <div class="col-9 fs-15">{{$t('daystate.total')}}</div>
                        <div class="col-3 fs-15 text-end">{{formatCurrency(newTotal, true)}}</div>
                    </div>
                    <div class="row border-top py-2">
                        <div class="col-9 fs-15 text-success pt-x fw-bold">{{$t('daystate.cash_removed_bank')}}</div>
                        <div class="col-3 fs-15" v-if="canSeeDeposit">
                            <FormItem type="currency-and-amount" extraOption="fs-15" :id="'saldo-tri'" startTag="€" v-model="item.daystate_check_safe.deposit_amount" @onchange="setNewAmount(item)" />
                        </div>
                        <div class="col-3 fs-15 text-end py-2" v-else> {{formatCurrency(item.daystate_check_safe.deposit_amount, true)}} </div>
                    </div>
                    <div class="row border-top py-3 fw-bold negative">
                        <div class="col-9">{{$t('daystate.saldo_new')}}</div>
                        <div class="col-3 text-end">{{formatCurrency(newAmount, true)}}</div>
                    </div>
                </section>
                <section v-show="step == 6" id="step-6">
                    <h2 class="pb-2">{{$t('daystatetabs.steps')}}</h2>
                    <div v-for="thisstep in stepData" :key="thisstep.id" class="row w-50">
                        <div class="col-auto pe-2">{{thisstep.id}}.</div>
                        <div class="col">{{$t('daystatetabs.step_' + thisstep.id)}}</div>
                        <i class="col-auto fa" :class="(thisstep.incomplete ? 'fa-close text-danger' : 'fa-check text-success')"></i>
                    </div>
                    <div class="w-50 pt-4">
                        <h2 class="pb-2"><i class="far fa-user me-2 fs-15"></i>{{$t('daystatetabs.step_6')}}</h2>
                        <FormItem type="text" v-if="item.location.responsible_person_required" v-model="item.daystate_check_accountability.accountable_name" :label="$t('daystate.name_accountable')" />
                        <FormItem type="text" v-model="item.daystate_check_accountability.supervised_by_name" :label="$t('daystate.name_supervisor')" />
                    </div>
                    <br />
                </section>

                <div class="justify-content-between mt-3 sticky-bottom" :class="{'d-none': step == 0, 'd-flex': step != 0}">
                    <div>
                        <button type="button" class="btn btn-unidark prev-button" @click="prev" v-show="step != 1">
                            <i class="far fa-chevron-left"></i>{{$t('form.previous')}}
                        </button>
                    </div>
                    <div>
                        <button type="button" class="btn btn-unilight save-button px-4 me-2" :disabled="isSaving || item.finalised" @click="save('controle')">
                            <i class="far fa-save me-2"></i>{{$t('form.save')}}
                        </button>
                        <button v-if="step != 6" type="button" class="btn btn-unidark next-button ms-2" @click="next" v-show="step != maxStep">
                            <i class="far fa-chevron-right"></i>{{$t('form.next')}}
                        </button>
                        <button v-else-if="stepData.find(astep => astep.incomplete)" type="button" class="btn btn-unilight save-button px-4 me-2" :disabled="true">
                            <i class="far fa-save me-2"></i>{{ prevDaystatesFinalized ? $t('daystate.save_finalize') : $t('daystate.save_concept')}}
                        </button>
                        <button v-else-if="prevDaystatesFinalized" type="button" class="btn btn-unilight save-button px-4 me-2" :disabled="isSaving || item.finalised || !canFinaliseDaystate" @click="save('controle', 'finalize')">
                            <i class="far fa-save me-2"></i>{{$t('daystate.save_finalize')}}
                        </button>
                        <button v-else type="button" class="btn btn-unilight save-button px-4 me-2" :disabled="isSaving || item.finalised" @click="save('controle', 'concept')">
                            <i class="far fa-save me-2"></i>{{$t('daystate.save_concept')}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="info-3" role="tabpanel" aria-labelledby="info-3-tab">
                <div class="position-relative">
                    <table role="table" class="p-datatable-table">
                        <thead class="p-datatable-thead position-sticky" role="rowgroup" data-pc-section="thead">
                            <tr class="p-sortable-column" tabindex="0" role="columnheader">
                                <th class="p-datatable-headercell" style="width: 15rem">{{$t('navigation.users')}}</th>
                                <th class="p-datatable-headercell">{{$t('overview.actions')}}</th>
                                <th class="p-datatable-headercell" style="width: 15rem">{{$t('overview.date')}}</th>
                                <th style="width: 1rem"></th>
                            </tr>
                        </thead>
                        <tbody class="p-datatable-tbody" role="rowgroup">
                            <a role="row" class="p-datatable-row" v-for="log in item.log_requests" :key="log.id" :href="'../logs/'+log.id" target="_blank">
                                <td role="cell" class="p-datatable-cell">{{log.user ? log.user.name : '-'}}</td>
                                <td role="cell" class="p-datatable-cell">{{log.formatted_log}}</td>
                                <td role="cell" class="p-datatable-cell">{{formatDate(log.created_at, 'logDateTime')}}</td>
                                <th></th>
                            </a>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import dsService from '@/services/DaystateService';
    import locationService from '@/services/CustomerService';
    import FooterButtons from '@/components/FooterButtons.vue';
    import store from '@/store/user';
    export default {
        name: 'DaystateDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "current_date": null,
                    'import_sync' : null,
                    'export_sync': null,
                    'total_payment' : "",
                    'total_turnover' : "",
                    'location_id': null,
                    "name": "",
                    "consolidated_income": {"total": 0, "lines": []},
                    "consolidated_revenue": {"total": 0, "lines": []},
                    "location": {
                        "name": "",
                        "cash_registers": []
                    },
                    "payTypes": [],
                    "bills": [],
                    "coins": [],
                    "daystate_check_accountability": {},
                    "daystate_check_income_and_expenses": [],
                    "daystate_check_payment_terminals": [],
                    "daystate_check_safe": {},
                    "daystate_check_cash_accounting": {},
                    "log_requests": []
                },
                locations: [],
                suppliers: [],
                totalIncome: 0,
                totalRevenue: 0,
                totalDeposit: 0,
                crossIncome: 0,
                crossRevenue: 0,
                totalEFT: 0,
                totalATMs: 0,
                depositsEFT: 0,
                depositsCash: 0,
                totalCasshierCash: 0,
                totalOnttroken: 0,
                diffIncomeExpenses: 0,
                bookkeeppingTable : "",
                prevDaystatesFinalized: true,
                checkCounting: true,
                chooseMultipleDates: 0,
                minDate: null,
                maxDate: null,
                splitCostPlace: false,
                activeUntil: 1,
                step: 1,
                maxStep: 6,
                payTypesArray: [],
                cashTypesArray: [],
                billTypesArray: ["100", "50", "20", "10", "5"],
                coinTypesArray: ["2", "1", "0.50", "0.20", "0.10", "0.05"],
                coinTotals: [],
                stepData: [
                    { id: 1, icon: "far fa-mobile", incomplete: false },
                    { id: 2, icon: "far fa-wallet", incomplete: false },
                    { id: 3, icon: "far fa-coins", incomplete: false },
                    { id: 4, icon: "far fa-desktop", incomplete: false },
                    { id: 5, icon: "far fa-vault", incomplete: false }
                ],
                differenceInTotals: false,
                isNew: true,
                isSaving: false,
                isImporting: false,
                isExporting: false,
                loading: true
            }
        },
        computed: {
            totalCash(){
                return this.isNew ? 0 : (this.item.consolidated_income.total_cash + this.diffIncomeExpenses + (this.totalEFT - this.totalATMs) + this.depositsCash);
            },
            newTotal() {
                return (this.item.daystate_check_safe.current_amount || this.item.daystate_check_safe.old_amount) - this.totalOnttroken + (this.checkCounting ? this.calculateGrandTotal({}, this.cashTypesArray, false) : 0);
            },
            newAmount() {
                return this.newTotal - (this.item.daystate_check_safe.deposit_amount || 0);
            },
            canFinaliseDaystate(){
                return store.getters.canFinaliseDaystate;
            },
            canSeeSafeMutations(){
                return store.getters.canSeeSafeMutations;
            },
            canSeeDeposit(){
                return store.getters.canSeeDeposit;
            },
        },
        watch: {
            totalCash: function(){
                this.stepData[3].incomplete = (this.differenceInTotals && !this.item.daystate_check_cash_accounting.accept_cash_difference) || !this.item.daystate_check_accountability.counted_by_name;
                if(this.stepData[3].incomplete) this.activeUntil = 4;
            }
        },
        methods: {
            save(saveType = null, saveParameter = null) {
                if(this.isNew){
                    let dateArray = [];
                    if((!this.item.from_date) || (this.chooseMultipleDates && !this.item.to_date)){
                        this.toastError('Geen data ingevoerd');
                        return;
                    }
                    if(this.chooseMultipleDates){
                        let currentDate = new Date (this.item.from_date);
                        let toDate = new Date (this.item.to_date);
                        let dateDifference = Math.floor(Math.abs(toDate - currentDate) / (1000 * 60 * 60 * 24));
                        if(dateDifference > 31){
                            this.toastError('U mag voor maximaal één maand dagstaten toevoegen.');
                            return;
                        }
                        while (currentDate <= toDate) {
                            dateArray.push(currentDate.toISOString().split('T')[0]);
                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        if(!dateArray[0]){
                            this.toastError('Datums staan niet in de juiste volgorde');
                            return;
                        }
                        this.item.dates = dateArray;
                    }
                    else{
                        this.item.dates = [this.item.from_date];
                    }
                    this.isSaving = true;
                    dsService.store(this.item).then(response => {
                        this.$router.push({'name': "Dagstaten"});
                    }).catch(e => {
                        this.isSaving = false;
                        this.toastError(e);
                    });
                }
                else if(saveType == 'controle'){
                    this.isSaving = true;
                    let item = {...this.item};
                    item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents = [];
                    item.bills.forEach(bill => {
                        this.cashTypesArray.forEach((cashType, i) => {
                            if(!bill[cashType.id] || bill[cashType.id].amount === '') return;
                            item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents.push({
                                id: bill[cashType.id].id,
                                cash_amount_type: bill.id,
                                quantity: bill[cashType.id].amount,
                                cash_register_id: cashType.id,
                                daystate_check_cash_accounting_id: item.daystate_check_cash_accounting.id
                            });
                        });
                    });
                    if(item.daystate_check_cash_accounting.specify_coins){
                        item.coins.forEach(coin => {
                            this.cashTypesArray.forEach((cashType, i) => {
                                if(!coin[cashType.id] || coin[cashType.id].amount === '') return;
                                item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents.push({
                                    id: coin[cashType.id].id,
                                    cash_amount_type: coin.id,
                                    quantity: coin[cashType.id].amount,
                                    cash_register_id: cashType.id,
                                    daystate_check_cash_accounting_id: item.daystate_check_cash_accounting.id
                                });
                            });
                        });
                    }
                    this.cashTypesArray.forEach((cashType, i) => {
                        let coin = item.coinTotals;
                        if(!coin[cashType.id] || coin[cashType.id].amount === '') return;
                        item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents.push({
                            id: coin[cashType.id].id,
                            cash_amount_type: coin[cashType.id].amount,
                            quantity: -1,
                            cash_register_id: cashType.id,
                            daystate_check_cash_accounting_id: item.daystate_check_cash_accounting.id
                        });
                    });
                    item.daystate_check_cash_accounting.cash_total = this.calculateGrandTotal({}, this.cashTypesArray, false);
                    item.daystate_check_payment_terminals = [];
                    for(let payTypeIndex in item.payTypes){
                        let payType = item.payTypes[payTypeIndex];
                        this.payTypesArray.forEach((arrayItem) => {
                            if(!payType[arrayItem.id]) return;
                            item.daystate_check_payment_terminals.push({
                                amount: payType[arrayItem.id].amount,
                                payment_method_id: arrayItem.id,
                                payment_terminal_id: payType.id,
                                daystate_id: item.id,
                                id: payType[arrayItem.id].id,
                            });
                        });
                    }
                    if(item.daystate_check_safe.accept_cash_difference == 0)
                        item.daystate_check_safe.current_amount = item.daystate_check_safe.old_amount;
                    item.daystate_check_safe.new_amount = this.newAmount;

                    item = {
                        id: item.id,
                        daystate_check_accountability: item.daystate_check_accountability,
                        daystate_check_cash_accounting: item.daystate_check_cash_accounting,
                        daystate_check_payment_terminals: item.daystate_check_payment_terminals,
                        daystate_check_safe: item.daystate_check_safe,
                        daystate_check_income_and_expenses: item.daystate_check_income_and_expenses
                    };
                    if(saveParameter == 'finalize'){
                        item.finalised = true;
                    }
                    else if(saveParameter == 'concept'){
                        item.finalised = false;
                        if(!confirm(this.$t('daystate.save_concept_confirm'))){
                            this.isSaving = false;
                            return;
                        }
                    }
                    return dsService.updateControl(item).then(() => this.$router.push({'name': "Dagstaten"})
                    ).catch(e => {
                        this.isSaving = false;
                        this.toastError(e);
                    });
                }
                else{
                    this.$router.push({'name': "Dagstaten"});
                }
            },
            back() {
                this.$router.push({'name': "Dagstaten"});
            },
            prev() {
                this.step -= 1;
            },
            next() {
                if(!this.validate()){
                    return;
                }
                this.step += 1;
                this.activeUntil = Math.max(this.activeUntil, this.step);
            },
            setStep(step){
                if(this.activeUntil >= step){
                    this.step = step;
                }
            },
            validate(checkForStep = null){
                const step = checkForStep || this.step;
                const els = document.querySelectorAll(`#step-${step} input[type='text'], #step-${step} input.number-format`);
                let hasDataFilledIn = true;
                els.forEach(el => {
                    if(!el.value){
                        hasDataFilledIn = false;
                    }
                })
                if(step == 2 || step == 5){
                    this.stepData[step - 1].incomplete = !hasDataFilledIn;
                }
                if(step == 4 && this.differenceInTotals && !this.item.daystate_check_cash_accounting.accept_cash_difference && this.checkCounting){
                    if(!checkForStep) this.toastError("Het kasverschil moet nul zijn of geaccepteerd worden.");
                    this.stepData[step - 1].incomplete = true;
                    return false;
                }
                if(step == 4 && !this.checkCounting){
                    this.stepData[step - 1].incomplete = !this.item.daystate_check_accountability.counted_by_name;
                }
                return checkForStep ? !this.stepData[step - 1].incomplete : true;
            },
            show(id){
                dsService.show(id).then(response => {
                    this.isImporting = false;
                    this.isExporting = false;
                    this.loading = false;
                    this.item = response.data;
                    this.item.name = this.item.location.name;
                    this.totalIncome = this.item.consolidated_income.total ;
                    this.checkCounting = this.item.location.daystate_cash_mode != 'accountability';
                    this.splitCostPlace = this.item.location.split_on_costcenter;

                    this.totalRevenue = this.item.consolidated_revenue.total;
                    this.suppliers = this.item.location ? this.item.location.suppliers : [];

                    this.crossIncome = this.crossRevenue = 0;
                    if(this.totalIncome > this.totalRevenue){
                        this.crossRevenue = this.totalIncome - this.totalRevenue
                    }
                    else{
                        this.crossIncome = this.totalRevenue - this.totalIncome;
                    }
                    //Autofill
                    this.item.payTypes = {};
                    this.payTypesArray = [];
                    this.item.location.payment_methods.forEach((payType, i) => {
                        if(payType.payment_type == 1) {
                            this.payTypesArray.push({id: payType.id, name: payType.name, total: ''});
                        }
                    });

                    this.item.daystate_deposits.forEach(deposit => {
                        const amount = Number(deposit.amount);
                        const paytype = deposit.paymentmethod.payment_type !== null ? deposit.paymentmethod.payment_type : deposit.paymentmethod.source_payment_type;
                        this.totalDeposit += amount;
                        if(paytype == 0) this.depositsCash += amount;
                        if(paytype == 1) this.depositsEFT += amount;
                    });
                    this.totalEFT = this.depositsEFT + this.item.consolidated_income.total_eft;

                    this.item.location.payment_terminals.forEach((payType, i) => {
                        this.item.payTypes[payType.id] = {id: payType.id, name: payType.name, total: ''};
                        this.payTypesArray.forEach(newPayType => this.item.payTypes[payType.id][newPayType.id] = {id: null, amount: ''});
                    });


                    if(this.item.daystate_check_payment_terminals !== null && this.item.daystate_check_payment_terminals.length > 0){
                        this.item.daystate_check_payment_terminals.forEach(payType => {
                            for(let newPaytypeindex in this.item.payTypes) {
                                if(this.item.payTypes[newPaytypeindex].id == payType.payment_terminal_id){
                                    this.item.payTypes[newPaytypeindex][payType.payment_method_id] = {id: payType.id, amount: payType.amount};
                                }
                            }
                        });
                    } else {
                        for(let payType of this.payTypesArray){
                            for(let incomeLine of this.item.consolidated_income.lines){
                                console.log("PayType", payType.name, payType.id, "IncomeLine", incomeLine.name, incomeLine.payment_method_id);
                                if(payType.id == incomeLine.payment_method_id){
                                    let firstTerminal = this.item.location.payment_terminals[0];
                                    this.item.payTypes[firstTerminal.id][incomeLine.payment_method_id].amount = incomeLine.subtotal;
                                    break;
                                }
                            }
                            break;
                        }
                    }


                    if(this.item.daystate_check_income_and_expenses === null){
                        this.item.daystate_check_income_and_expenses = [];
                    }
                    this.item.daystate_check_income_and_expenses.forEach(incomeLine => {
                        if(incomeLine.supplier && incomeLine.supplier.withdraw_from_safe == 1)
                        {
                            this.totalOnttroken -= incomeLine.expense;
                            this.totalOnttroken += incomeLine.income;
                        }
                    });

                    this.diffIncomeExpenses = this.item.daystate_check_income_and_expenses.reduce((sum, val) => sum + Number(val.income), 0) -
                                              this.item.daystate_check_income_and_expenses.reduce((sum, val) => sum + Number(val.expense), 0);

                    this.item.bills = [];
                    this.cashTypesArray = this.item.location.cash_registers;
                    this.billTypesArray.forEach((payType, i) => {
                        this.item.bills[i] = {id: payType, name: payType, total: ''};
                        this.cashTypesArray.forEach(cashType => this.item.bills[i][cashType.id] = {id: null, amount: ''});
                    });
                    this.item.coins = [];
                    this.coinTypesArray.forEach((payType, i) => {
                        this.item.coins[i] = {id: payType, name: payType.replace('.', ','), total: ''};
                        this.cashTypesArray.forEach(cashType => this.item.coins[i][cashType.id] = {id: null, amount: ''});
                    });
                    this.item.coinTotals = {};
                    this.cashTypesArray.forEach(cashType => this.item.coinTotals[cashType.id] = {id: null, amount: ''});
                    if(this.item.daystate_check_cash_accounting && this.item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents !== null){
                        this.item.daystate_check_cash_accounting.daystate_check_cash_accounting_contents.forEach(cash => {
                            if(cash.quantity == -1){
                                this.item.coinTotals[cash.cash_register_id] = {id: cash.id, amount: cash.cash_amount_type};
                            }
                            else if(Number(cash.cash_amount_type) >= 5){
                                this.item.bills.forEach((bill, i) => {
                                    if(bill.id == cash.cash_amount_type){
                                        bill[cash.cash_register_id] = {id: cash.id, amount: cash.quantity};
                                    }
                                });
                            }
                            else{
                                this.item.coins.forEach((coin, i) => {
                                    if(coin.id == cash.cash_amount_type){
                                        coin[cash.cash_register_id] = {id: cash.id, amount: cash.quantity};
                                    }
                                });
                            }
                        });
                    }
                    if(this.item.daystate_check_cash_accounting === null){
                        this.item.daystate_check_cash_accounting = {
                            specify_coins: 0,
                            accept_cash_difference: 0,
                            cash_difference: 0,
                            notes: null,
                            daystate_check_cash_accounting_contents: []
                        }
                    }
                    if(this.item.daystate_check_safe === null){
                        this.item.daystate_check_safe = {
                            accept_difference: 0,
                            old_amount: 0,
                            new_amount: 0,
                            current_amount: 0,
                            deposit_amount: 0
                        }
                    }
                    if(this.item.daystate_check_accountability === null){
                        this.item.daystate_check_accountability = {
                            counted_by_name: '',
                            accountable_name: '',
                            supervised_by_name: ''
                        };
                    }
                    const checkField = this.item.location.responsible_person_required ? 'accountable_name' : 'supervised_by_name';
                    this.item.daystate_check_accountability[checkField] = this.item.daystate_check_accountability[checkField] || store.getters.getUser.name;
                    this.$nextTick(() => {
                        this.calculateGrandTotal({}, this.cashTypesArray);
                        this.totalOnttroken = 0;
                        this.item.daystate_check_income_and_expenses.forEach(incomeLine => {
                            if(incomeLine.supplier && incomeLine.supplier.withdraw_from_safe == 1)
                            {
                                this.totalOnttroken -= incomeLine.expense;
                                this.totalOnttroken += incomeLine.income;
                            }
                        });

                        for(let i = 1; i < this.maxStep; i++){
                            const isValid = this.validate(i);
                            if(isValid)
                                this.activeUntil++;
                            else
                                break;
                        }
                    });
                });
            },
            importData(){
                const locationId = this.$route.params.id;
                this.isImporting = true;
                dsService.import(locationId).then(() => {
                    this.show(this.item.id);
                    dsService.exportlines(locationId, 'html').then(response => {
                        this.bookkeeppingTable = response.data;
                    }).catch(e => console.log(e) );
                }
                ).catch(e => {
                    this.isImporting = false;
                    const error = e.response.data && e.response.data.message ? e.response.data.message : (e.response.data ? e.response.data : 'Fout bij het exporteren');
                    this.$toast.error(error, {'duration': 5000});
                });
            },
            exportData(){
                if((this.item.export_sync || this.item.status == 'ok') && !confirm('De dagstaat is al geëxporteerd. Weet u zeker dat u opnieuw wilt exporteren?')) return;
                const locationId = this.$route.params.id;
                this.isExporting = true;
                dsService.export(locationId).then(() => {
                    this.show(this.item.id);
                    dsService.exportlines(locationId, 'html').then(response => {
                        this.bookkeeppingTable = response.data;
                    }).catch(e => console.log(e) );
                }
                ).catch(e => {
                    this.isExporting = false;
                    this.toastError(e);
                });
            },
            downloadExport(){
                const locationId = this.$route.params.id;
                this.isExporting = false;
                dsService.download(locationId).then((response) => {
                    //Return the response data to the client as a download with the id of the location as the filename
                    this.triggerDownload(response.data, this.item.name.replaceAll(' ', '_') + '_' + this.formatDate(this.item.current_date, "shortDate") + ".txt")


                }
                ).catch(e => {
                    this.isExporting = false;
                    this.toastError(e);
                });
            },
            exportLines(){
                const locationId = this.$route.params.id;
                this.isExporting = true;
                dsService.exportlines(locationId, 'html').then(response => {
                    this.show(this.item.id);
                }).catch(e => {
                    this.isExporting = false;
                    this.toastError(e);
                });
            },
            downloadExcel(){
                return dsService.exportlines(this.item.id, 'excel').then(response => {
                    this.triggerDownload(response.data, this.item.name.replaceAll(' ', '_') + '__' + this.formatDate(this.item.current_date, "shortDate") + ".xlsx")
                }).catch(e => {
                    this.toastError(e);
                });
            },
            openCollapse(e, id){
                if(e.target.tagName == 'BUTTON' || e.target.tagName == 'I') return;
                document.getElementById('record-' + id).click();
            },
            setDateLimit(){
                if(!this.item.from_date) return;
                let newMaxDate = new Date (this.item.from_date);
                this.minDate = newMaxDate.toISOString().split('T')[0];
                newMaxDate.setMonth(newMaxDate.getMonth() + 1);
                newMaxDate.setDate(newMaxDate.getDate() - 1);
                this.maxDate = newMaxDate.toISOString().split('T')[0];
                if(this.item.to_date){
                    let toDate = new Date (this.item.to_date);
                    if(toDate > newMaxDate){
                        this.item.to_date = newMaxDate.toISOString().split('T')[0];
                    }
                }
            },

            calculateTotal(field, itemArray = null, containerArray = null, billAmount = 0, isFormatted = true){
                let total = 0;
                const loopArray = containerArray ? (Array.isArray(containerArray.constructor) ? containerArray : Object.values(containerArray)) : null;
                const loopArray2 = itemArray ? (Array.isArray(itemArray) ? itemArray : Object.values(itemArray)) : null;
                if(loopArray && loopArray2)
                    loopArray.forEach(container => loopArray2.forEach(arrayItem => total += (parseFloat(container[arrayItem.id || arrayItem].amount || 0) * (billAmount ? parseFloat(container.id) : 1))));
                else if(loopArray2)
                    loopArray2.forEach(bill => total += (parseFloat(field[bill.id || bill].amount || 0) * (billAmount ? parseFloat(field.id) : 1)));
                else
                    loopArray.forEach(bill => total += (parseFloat(bill[field.id || field].amount || 0) * (billAmount ? parseFloat(bill.id) : 1)));
                if(isFormatted == 'saveatm')
                    this.totalATMs = total;
                return isFormatted ? this.formatCurrency(total, true) : total;
            },

            calculateGrandTotal(field, itemArray = null, formatted = true){
                let total = this.calculateTotal(field, itemArray, this.item.bills, true, false);
                if(this.item.daystate_check_cash_accounting.specify_coins)
                    total += this.calculateTotal(field, itemArray, this.item.coins, true, false);
                total += itemArray ? Object.values(this.item.coinTotals).reduce((sum, val) => sum + Number(val.amount), 0) : Number(this.item.coinTotals[field.id].amount);
                if(itemArray) {
                    const totalCash = Number(this.totalCash);
                    this.differenceInTotals = Number(total) - totalCash != 0;
                    this.item.daystate_check_cash_accounting.cash_difference = total - totalCash;

                    this.totalCasshierCash = total;
                }
                return formatted ? this.formatCurrency(total, true) : total;
            },
            setSupplier(input, item){
                item.supplier = input;
                item.supplier_name = input.name;
                this.totalOnttroken = 0;
                this.item.daystate_check_income_and_expenses.forEach(incomeLine => {
                    if(incomeLine.supplier && incomeLine.supplier.withdraw_from_safe == 1)
                    {
                        this.totalOnttroken -= incomeLine.expense;
                        this.totalOnttroken += incomeLine.income;
                    }
                });


            },
            setCurrentAmount(item){
                if(!item.daystate_check_safe.accept_cash_difference)
                    item.daystate_check_safe.current_amount = item.daystate_check_safe.old_amount;
                this.setNewAmount(item);
            },
            setNewAmount(item){
                item.daystate_check_safe.new_amount = Number(item.daystate_check_safe.current_amount) - Number(item.daystate_check_safe.deposit_amount || 0.0);
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            if(this.isNew){
                locationService.index().then(response => {
                    this.locations = response.data;
                    this.locations.forEach(loc => loc.name = loc.full_name);
                    this.locations.sort((a, b) => a.name > b.name);
                });
                this.item = {
                    "from_date": new Date().toISOString().split('T')[0],
                    "to_date": new Date().toISOString().split('T')[0],
                    "location_id": 1,
                    'total_payment': 0,
                    'total_turnover': 0,
                };
            }
            else{
                this.show(locationId);
                dsService.exportlines(locationId, 'html').then(response => {
                    this.bookkeeppingTable = response.data;
                }).catch(e => {
                    console.log(e);
                });
            }
        }
    }

</script>
<style scoped>
#myTab2Content section{
    width:50%;
}
#myTab2Content section:nth-child(2){
    padding-left: calc(var(--bs-gutter-x)* .5)
}
#progress-bar > div.incomplete > i{
    color: #EF8C19;
    background-color: #FADCBA;
    border-color: #FADCBA;
}
#step-5 .col-9{
    width: calc(100% - 10rem)
}
#step-5 .col-3{
    width: 10rem
}

#step-4 .d-flex .col-auto:first-child{
    min-width: 130px !important;
    flex: 1 0 0%;
}
#step-4 .d-flex .col-auto:last-child{
    width: 130px !important
}
.border-top{
    border-top: 1px solid #bfbfbf;
}
.negative{
    background-color: #F3F7FA;
}
.total-row{
    font-size: 17px;
    font-weight: 700;
    color: var(--uni-dark)
}
.warning-badge{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 17px 20px
}
.big-badge{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #fff;
}
.action-bar{
    position: absolute;
    width: 50%;
    right: 3rem;
}
.active.da-flex{
    display: flex;
    flex-wrap: wrap;
}
.p-row{
    padding: 5px 0;
}
.pt-x{
    padding-top: 6px
}
.hoverable:hover{
    cursor: pointer;
    background-color: #f8f9fa
}
.p-datatable-row{
    display: table-row;
    text-decoration: none !important;
}
.p-datatable-row:hover{
    cursor: pointer;
    background-color: #eee;
}
#dropdownMenuButton2.show{
    background-color: var(--uni-dark) !important;
    color: white !important;
}
#loadingscreen{
    width: calc(100vw - 5rem);
    height: calc(100vh - 5rem);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5rem;
    left: 5rem;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;
}
</style>
<style>
#bookkeeping-table{
    border-collapse: collapse;
    border: 1px solid var(--uni-gray);
    margin-top: 1rem;
    width: 100%
}
#bookkeeping-table > thead > tr > th, #bookkeeping-table > tbody > tr > td{
    border: 1px solid var(--uni-gray);
    padding: .1rem .5rem;
    font-size: 14px;
    font-weight: 500
}
#info-2.finalised input, #info-2.finalised select, #info-2.finalised textarea, #info-2.finalised .curr-preview, #info-2.finalised .bs-switch{
    pointer-events: none;
    background-color: #f8f9fa;
}
.fa-info-circle + div{
    position: absolute;
    translate: 8.5rem -5%;
    color: white;
    opacity: 1;
}
.fa-info-circle + article{
    position: absolute;
    translate: 10px -1px;
    color: white;
    opacity: 1;
    display: inline-block;
}
.fa-info-circle:not(:hover) + div, .fa-info-circle:not(:hover) + article{
    display: none !important;
}
</style>
